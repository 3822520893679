import React from "react";
import LetsChange from "../images/bigText/lets-change.png";
import TheWay from "../images/bigText/the-way.png";
import WeEat from "../images/bigText/we-eat.png";
import GuillemetOpen from "../images/bigText/guillementOuvrant.png";
import GuillemetClose from "../images/bigText/guillementFermant.png";
import "./_BigText.scss"

const BigText = () => <div className="content-big-text">
    <div>
        <img className="guillemet"
             src={GuillemetOpen} alt="guillemet ouvrant"/>
    </div>
    <div className="big-text-home">
        <img className="lets-change"
             src={LetsChange} alt="let's change"/>
        <img className="the-way" src={TheWay}
             alt="the way"/>
        <img className="we-eat" src={WeEat}
             alt="we eat"/>
    </div>
    <div className="guillemet-close-content">
        <img className="guillemet"
             src={GuillemetClose} alt="guillemet ouvrant"/>
    </div>
</div>

export default BigText;