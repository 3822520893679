import React from 'react';
import Logo from "./images/logo.png";
import BigText from "./components/BigText";
import "./_App.scss";

const App = () => <div className="app">
    <div className="text">
        Site en construction
    </div>
    <img className="logo" src={Logo} alt="logo" />
    <BigText />
</div>;

export default App;